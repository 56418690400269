import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TokenTable from "~/components/TokenTable/TokenTable";
export const _frontmatter = {
  "menuLabel": "Border Radius",
  "sortOrder": 5.1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Border Radius`}</h1>
    <p className="p-large">We use rounded corners to make our sportsbook more friendly and modern.</p>
    <p>{` We've curated our options to just three for ease of use. `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Small (sm)`}</strong>{` tokens can be used for smaller UI elements, like market selection buttons.`}</li>
      <li parentName="ul"><strong parentName="li">{`Medium (md)`}</strong>{` tokens can be used for cards, alerts or modals.`}</li>
      <li parentName="ul"><strong parentName="li">{`Large (lg)`}</strong>{` tokens can be used for buttons, pills and larger elements, like drawers.`}</li>
    </ul>
    <TokenTable tokenGroup="BorderRadius" type="Semantic" className="mt-8" mdxType="TokenTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      